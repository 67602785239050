import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slider-gallery-g3",
  templateUrl: "./slider-gallery-g3.component.html",
  styleUrls: ["./slider-gallery-g3.component.scss"],
})
export class SliderGalleryG3Component implements OnInit {
  title = "ngSlick";

  slides = [
    { img: "/assets/img/g3-gallery-dt-01.webp" },
    { img: "/assets/img/g3-gallery-dt-02.webp" },
    { img: "/assets/img/g3-gallery-dt-03.webp" },
    { img: "/assets/img/g3-gallery-dt-04.webp" },
    { img: "/assets/img/g3-gallery-dt-05.webp" },
    { img: "/assets/img/g3-gallery-dt-06.webp" },
    { img: "/assets/img/g3-gallery-dt-07.webp" },
    { img: "/assets/img/g3-gallery-dt-08.webp" },
    { img: "/assets/img/g3-gallery-dt-09.webp" },
    { img: "/assets/img/g3-gallery-dt-10.webp" },
    { img: "/assets/img/g3-gallery-dt-11.webp" },
    { img: "/assets/img/g3-gallery-dt-12.webp" },
  ];
  slidesmob = [
    { img: "/assets/img/g3-gallery-mob-01.webp" },
    { img: "/assets/img/g3-gallery-mob-02.webp" },
    { img: "/assets/img/g3-gallery-mob-03.webp" },
    { img: "/assets/img/g3-gallery-mob-04.webp" },
    { img: "/assets/img/g3-gallery-mob-05.webp" },
    { img: "/assets/img/g3-gallery-mob-06.webp" },
    { img: "/assets/img/g3-gallery-mob-07.webp" },
    { img: "/assets/img/g3-gallery-mob-08.webp" },
    { img: "/assets/img/g3-gallery-mob-09.webp" },
    { img: "/assets/img/g3-gallery-mob-10.webp" },
    { img: "/assets/img/g3-gallery-mob-11.webp" },
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
  };

  slidemobConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
  };

  constructor() {}

  ngOnInit() {}
}
