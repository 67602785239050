import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.addTags([
      { name: 'title', content: 'Grailer Knives: holy grail knives with a story.' },

      { name: 'description', content: 'Every knife enthusiast has one, a knife that always feels out of reach. The holy grail. A grail knife. We decided to make grail knives available to everyone.' }
    ]); 

  } 
  ngOnInit() {
  }

}
