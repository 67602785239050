import { Component } from '@angular/core';

import { SlideInOutAnimation } from '../../../animations';
import { menuTopAnimation } from '../../../animations';
import { menuBottomAnimation } from '../../../animations';
import { navMenuAnimation } from '../../../animations';
import { navCloseAnimation } from '../../../animations';


@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  animations: [SlideInOutAnimation, menuTopAnimation, menuBottomAnimation, navMenuAnimation, navCloseAnimation ]
})
export class HeaderNavigationComponent {

  constructor() { }
  menuState:string = 'in';
 
  toggleMenu(divName: string) {
    if (divName === 'overlay, menu-top, menu-bottom, nav-menu, nav-close') {
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
      console.log(this.menuState);
    }
  }

  //classActive = false;

  //toggleClass() {
  //this.classActive = !this.classActive;
  //}

}

