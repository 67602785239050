import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';


@Component({
  selector: 'app-about-hero',
  templateUrl: './about-hero.component.html',
  styleUrls: ['./about-hero.component.scss']
})
export class AboutHeroComponent implements OnInit {

  page: Object;

  constructor(private contentService: ContentService) { }


  ngOnInit() {
    this.page = this.contentService.pages['about-hero'];
  }

}
