import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.addTags([
      { name: 'title', content: 'Who is Grailer? The story of Grailer Knives.' },

      { name: 'description', content: 'Grailer is the brainchild of Knivesandtools. After being in the knife business for 20 years, you get to a point when you realize you want to start making your own.' }
    ]); 

  }   

  ngOnInit() {
  }

}
