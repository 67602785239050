import { Component, OnInit } from "@angular/core";
import { IParallaxScrollConfig } from "ngx-parallax-scroll";

@Component({
  selector: "app-hero-designer-g4",
  templateUrl: "./hero-designer-g4.component.html",
  styleUrls: ["./hero-designer-g4.component.scss"],
})
export class HeroDesignerG4Component {
  ngParallaxConf: IParallaxScrollConfig = {
    parallaxSpeed: 0.09,
    parallaxSmoothness: 0,
    parallaxDirection: "reverse",
    parallaxTimingFunction: "ease-out",
    parallaxThrottleTime: 0,
  };
  constructor() {}
}
