import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hero-video-g4",
  templateUrl: "./hero-video-g4.component.html",
  styleUrls: ["./hero-video-g4.component.scss"],
})
export class HeroVideoG4Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
