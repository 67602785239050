import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designer-g3',
  templateUrl: './designer-g3.component.html',
  styleUrls: ['./designer-g3.component.scss']
})
export class DesignerG3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
