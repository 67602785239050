import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  


@Component({
  selector: 'app-grailer-one',
  templateUrl: './grailer-one.component.html',
  styleUrls: ['./grailer-one.component.scss']
})
export class GrailerOneComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.addTags([
      { name: 'title', content: 'Grailer 1 pocket knife: Dirk de Wit design.' },

      { name: 'description', content: 'The Grailer 1 is an innovative pocket knife, designed by Dirk de Wit and made by LionSteel in Italy. The first Grailer knife and the start of an adventure.' }
    ]); 

  } 
  ngOnInit() {
  }

}
