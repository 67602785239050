import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-parallax-logo',
  templateUrl: './parallax-logo.component.html',
  styleUrls: ['./parallax-logo.component.scss']
})
export class ParallaxLogoComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }

}
