import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-video-g4-maintenance",
  templateUrl: "./video-g4-maintenance.component.html",
  styleUrls: ["./video-g4-maintenance.component.scss"],
})
export class VideoG4MaintenanceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
