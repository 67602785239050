import { Component } from "@angular/core";
import { IParallaxScrollConfig } from "ngx-parallax-scroll";

@Component({
  selector: "app-hero-designer-g2",
  templateUrl: "./hero-designer-g2.component.html",
  styleUrls: ["./hero-designer-g2.component.scss"],
})
export class HeroDesignerG2Component {
  ngParallaxConf: IParallaxScrollConfig = {
    parallaxSpeed: 0.09,
    parallaxSmoothness: 0,
    parallaxDirection: "reverse",
    parallaxTimingFunction: "ease-out",
    parallaxThrottleTime: 0,
  };

  constructor() {}
}
