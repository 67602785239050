import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';  


@Component({
  selector: 'app-grailer-two',
  templateUrl: './grailer-two.component.html',
  styleUrls: ['./grailer-two.component.scss']
})
export class GrailerTwoComponent implements OnInit {

  constructor(private meta: Meta) {

    this.meta.addTags([
      { name: 'title', content: 'Grailer 2 pocket knife: Simen Stryckers design.' },

      { name: 'description', content: 'The Grailer 2 is an exquisite gentleman’s folder and EDC-knife in one, designed by Simen Stryckers. The second Grailer knife, made by WE Knife in China.' }
    ]); 

  } 
  ngOnInit() {
  }

}
