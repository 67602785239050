import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  pages: Object = {
    'about': {
        title: 'About Grailer', 
        description: 'A platform to meet up with your active friends or find new ones to bike, run or hike together.', 
    },

    'grailer2': {
      title: 'knivesandtools', 
      description: 'A platform to meet up with your active friends or find new ones to bike, run or hike together.', 
    }

  };

  constructor() { }
}
