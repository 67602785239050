import { Component, HostListener } from "@angular/core";

@Component({
  selector: "app-header-logo",
  templateUrl: "./header-logo.component.html",
  styleUrls: ["./header-logo.component.scss"],
})
export class HeaderLogoComponent {
  scrolled: boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 720;
  }

  constructor() {}
}
