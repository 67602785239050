import { Component } from "@angular/core";
import { IParallaxScrollConfig } from "ngx-parallax-scroll";

@Component({
  selector: "app-hero-designer-g3",
  templateUrl: "./hero-designer-g3.component.html",
  styleUrls: ["./hero-designer-g3.component.scss"],
})
export class HeroDesignerG3Component {
  ngParallaxConf: IParallaxScrollConfig = {
    parallaxSpeed: 0.09,
    parallaxSmoothness: 0,
    parallaxDirection: "reverse",
    parallaxTimingFunction: "ease-out",
    parallaxThrottleTime: 0,
  };
  constructor() {}
}
