import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designer-g4',
  templateUrl: './designer-g4.component.html',
  styleUrls: ['./designer-g4.component.scss']
})
export class DesignerG4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
