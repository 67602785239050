import { Component, OnInit } from '@angular/core';
import { IParallaxScrollConfig } from 'ngx-parallax-scroll';

@Component({
  selector: 'app-hero-designer-g1',
  templateUrl: './hero-designer-g1.component.html',
  styleUrls: ['./hero-designer-g1.component.scss']
})
export class HeroDesignerG1Component implements OnInit {

  ngParallaxConf: IParallaxScrollConfig = {
    parallaxSpeed: 0.09,
    parallaxSmoothness: 0,
    parallaxDirection: 'reverse',
    parallaxTimingFunction: 'ease-out',
    parallaxThrottleTime: 0
  };

  constructor() { }

  ngOnInit() {
  }

}
