import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-gallery-g4',
  templateUrl: './slider-gallery-g4.component.html',
  styleUrls: ['./slider-gallery-g4.component.scss']
})
export class SliderGalleryG4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
