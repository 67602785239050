import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: "app-grailer-three",
  templateUrl: "./grailer-three.component.html",
  styleUrls: ["./grailer-three.component.scss"],
})
export class GrailerThreeComponent implements OnInit {
  constructor(private meta: Meta) {
    this.meta.addTags([
      {
        name: "title",
        content: "Grailer 3 pocket knife: Dirk de Wit design .",
      },

      {
        name: "description",
        content:
          "The Grailer 3 is a lightweight and characteristic EDC-knife. Designed by Dutch knifemaker Dirk de Wit. Manufactured by WE Knife.",
      },
    ]);
  }

  ngOnInit() {}
}
