import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-designer-g1',
  templateUrl: './designer-g1.component.html',
  styleUrls: ['./designer-g1.component.scss']
})
export class DesignerG1Component implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
